<template>
  <footer>Screend</footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped></style>
