<template>
  <header class="header">
    <Navbar />
  </header>
</template>

<script>
import Navbar from "./Navbar";

export default {
  name: "Header",
  components: {
    Navbar
  }
};
</script>

<style lang="scss" scoped></style>
